/* jslint browser: true */
/* global define */

/** A jQuery plugin to expand submenus */
define('menuExpander',['jquery'], function ($) {
	'use strict';

	$.fn.menuExpander = function (options) {
		// The settings object provides default settings.
		// The options argument can override them.
		var settings = $.extend({
			transitionTime: 250,
			cssClass: 'js-menu-expander',
			callback: null
		}, options);

		var $menu = this;

		var init = function() {
			var $menuItems;

			if ($menu.hasClass(settings.cssClass)) {
				// Already implemented. Nothing to do.
				return;
			}

			// Add class for styling
			$menu.addClass(settings.cssClass);

			// Add functionality
			$menuItems = $menu.find('> li');

			$menuItems.each(function() {
				var $li = $(this);
				var $trigger = $li.find('> a');
				var $submenu = $li.find('> ul');

				if ($submenu.length === 1) {
					// Add class and event listener
					$li.addClass(settings.cssClass + '-parent');

					$trigger.on('click.menuExpander', function(e) {
						// Only expand if top-level menu was clicked
						collapseMenus($trigger.parent().siblings());
						expandMenu($submenu);

						if (typeof settings.callback === 'function') {
							setTimeout(function() {
								settings.callback($trigger)
							}, settings.transitionTime);
						}

						return false;
					});

					// Hide menu by default
					$submenu.hide();
				}
			});
		};

		var expandMenu = function($submenu) {
			if (!$submenu.is(':visible')) {
				$submenu.stop(true, true).slideDown(settings.transitionTime);
				$submenu.parent().addClass(settings.cssClass + '-expanded');
			} else {
				collapseMenus($submenu.parent());
			}
		};

		var collapseMenus = function($parents) {
			$parents.find('> ul').stop(true, true).slideUp(settings.transitionTime);
			$parents.removeClass(settings.cssClass + '-expanded');
		};

		// Let's go!
		init();

		// Always return the target object to allow chaining.
		return this;
	};
});

