/* jslint browser: true */
/* global define */

/** A jQuery plugin to fix the header to the top of the page while scrolling */
define('fixedHeader',['jquery'], function ($) {
	'use strict';

	$.fn.fixedHeader = function (options) {
		var settings = $.extend({
			minWidth: 0,
			maxWidth: 9999,
			scrollChangeHeight: 'auto',
			defaultClassName: 'fixed-header',
			scrollClassName: 'fixed-header-scrolled',
			placeholderClassName: 'fixed-header-placeholder'
		}, options);
		var header = this;
		var isActive = false;
		var placeholder;

		var init = function() {
			$(window).on('resize.fixedHeader', function() {
				var widthMatches = matchMedia('only screen and (min-width: ' + settings.minWidth + 'px) and (max-width: ' + settings.maxWidth + 'px)').matches;

				if (!isActive && widthMatches) {
					implement();
				} else if (isActive && !widthMatches) {
					destroy();
				}
			}).trigger('resize.fixedHeader');
		};

		var implement = function() {
			// Create placeholder
			placeholder = $('<div class="' + settings.placeholderClassName + '"></div>');
			header.before(placeholder);

			// Add class
			header.addClass(settings.defaultClassName);

			// Set header to "fixed" position
			header.css({
				position: 'fixed',
				width: '100%'
			});

			// Adjust placeholder height
			setPlaceholderHeight();

			// Add scroll event listener
			$(window).on('scroll.fixedHeader', function() {
				assignCssClass($(window).scrollTop());
			}).trigger('scroll.fixedHeader');

			// Add window resize event listener
			$(window).on('resize.fixedHeader', function() {
				setPlaceholderHeight();
			});

			// Mark as "active"
			isActive = true;
		};

		var destroy = function() {
			// Reset CSS
			placeholder.remove();

			header.removeClass(settings.scrollClassName);

			header.css({
				position: 'relative',
				width: 'auto'
			});

			// Remove event listener
			$(window).off('scroll.fixedHeader');

			// Remove class
			header.removeClass(settings.defaultClassName);

			// Mark as not "active"
			isActive = false;
		};

		var setPlaceholderHeight = function() {
			var headerHeight = header.outerHeight();

			placeholder.height(headerHeight);

			if (settings.scrollChangeHeight === 'auto') {
				settings.autoScrollChangeHeight = headerHeight;
			}
		};

		var assignCssClass = function(scrollPosition) {
			var scrollChangeHeight = (typeof settings.scrollChangeHeight === 'number') ? settings.scrollChangeHeight : settings.autoScrollChangeHeight;

			if (scrollPosition > scrollChangeHeight){
				header.addClass(settings.scrollClassName);
			} else {
				header.removeClass(settings.scrollClassName);
			}
		};

		// Make it stick!
		init();

		// Always return the target object to allow chaining.
		return this;
	};
});

