/* jslint browser: true */
/* global define */

/** A jQuery plugin to expand text boxes and drop-downs on touch/click */
define('formFieldExpander',['jquery', 'matchMedia'], function ($) {
	'use strict';

	$.fn.formFieldExpander = function (options) {
		// The settings object provides default settings.
		// The options argument can override them.
		var settings = $.extend({
			activator: null,
			minWidth: 360
		}, options);

		var $formField = this;
		var isHidden = true;
		var initDims = {};

		var init = function() {
			var isIos = /iPad|iPhone|iPod/.test(navigator.platform);

			if ($formField.parent().hasClass('js-form-field-expander')) {
				// Already set up on this element
				return false;
			}

			// Set up elements
			initDims = getBoxDimensions();

			// Add event listeners
			settings.activator.on('mousedown.formFieldExpander touchstart.formFieldExpander', function() {
				if (isHidden && matchMedia('(min-width: ' + settings.minWidth + 'px)').matches) {
					expand();
				} else {
					collapse();
				}
			});

			$formField.on('blur.formFieldExpander', function() {
				collapse();
			});

			$(window).on('resize.formFieldExpander', function() {
				if (!$formField.is(':focus')) {
					expand(0, false);
					$formField.css('width', 'inherit');
					initDims = getBoxDimensions();
					collapse(0);
				} else if (!isIos) {
					// iOS triggers the resize event on scroll
					collapse(0);
				}
			});

			// Get ready
			$formField.parent().addClass('js-form-field-expander');
			collapse(0);
		};

		var getBoxDimensions = function() {
			var dims = {};

			dims.width = $formField.outerWidth();
			dims.paddingRight = $formField.css('padding-right');
			dims.paddingLeft = $formField.css('padding-left');
			dims.borderRight = $formField.css('border-right-width');
			dims.borderLeft = $formField.css('border-left-width');

			return dims;
		};

		var expand = function(duration, setFocus) {
			duration = (typeof duration !== 'undefined') ? duration : 200;
			setFocus = (typeof setFocus === 'undefined') ? true : setFocus;

			$formField.show();

			$formField.css({
				borderRightWidth: initDims.borderRight,
				borderLeftWidth: initDims.borderLeft
			});

			$formField.stop().animate({
				width: initDims.width,
				paddingRight: initDims.paddingRight,
				paddingLeft: initDims.paddingLeft,
			}, duration, function() {
				if (setFocus) {
					$formField.focus();
				}

				isHidden = false;
				$formField.parent().addClass('js-expanded');
			});
		};

		var collapse = function(duration) {
			duration = (typeof duration !== 'undefined') ? duration : 200;


			$formField.stop().animate({
				width: 0,
				paddingRight: 0,
				paddingLeft: 0
			}, duration, function() {
				$formField.hide();

				$formField.css({
					borderRightWidth: 0,
					borderLeftWidth: 0
				});

				isHidden = true;
				$formField.parent().removeClass('js-expanded');
			});
		};

		// Let's go!
		init();

		// Always return the target object to allow chaining.
		return this;
	};
});

