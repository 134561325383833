/* jslint browser: true */
/* global define */

/** A jQuery plugin for existing ZOLL captcha functionality */
define('setupCaptcha',['jquery'], function ($) {
	'use strict';

	$.setupCaptcha = function () {

		// The two functions below are deprecated for now, consider using the AJAX method of CAPTCHA outlines below, however
		var validateFrmOverride = function () {
			var FormIsValid = EkFmValidate(document.getElementById('ctl00_ctl00_mainMasterContentPlaceHolder_mainMasterContentPlaceHolder_FormBlock1'));
			if (FormIsValid) {
				WebForm_DoPostBackWithOptions(new WebForm_PostBackOptions("ctl00$ctl00$mainMasterContentPlaceHolder$mainMasterContentPlaceHolder$captchaSubmit", "", true, "", "", false, false));
				return true;
			}
			else {
				alert("Sorry, the information you entered in the form is not complete. Please try again.");
				return false;
			}
		};

		var validateCaptcha = function () {
			var FormIsValid = EkFmValidate(document.getElementById('ctl00_ctl00_mainMasterContentPlaceHolder_mainMasterContentPlaceHolder_FormBlock1'));

			if (FormIsValid == true) {
				challengeField = $("#recaptcha_challenge_field").val();
				responseField = $("#recaptcha_response_field").val();

				var html = $.ajax({
					type: "POST",
					url: "/ajax/recaptcha.ashx",
					data: "recaptcha_challenge_field=" + challengeField + "&recaptcha_response_field=" + responseField,
					async: false
				}).responseText;

				if (html == "True") {
					Ektron.FormBlock.setTargetAction("postback");
					Ektron.FormBlock.setState("__ekFormState", "in");
					return true;
				}
				else {
					alert("Sorry, the information you entered in the CAPTCHA field is incorrect. Please try again.");
					Recaptcha.reload();
					return false;
				}
			} else { return false; }
		}

		var populateRepForm = function () {
			var querystring = location.search.replace('?', '').split('&');

			if ($(querystring)[0]) {
				for (var i = 0; i < querystring.length; i++) {
					var name = querystring[i].split('=')[0];
					var value = querystring[i].split('=')[1];
					value = value.replace('+', ' ');

					if ($('input[name=' + name + ']')) {
						var v = decodeURIComponent(value);

						if (name.toLowerCase() != 'id' && name.toLowerCase() != 'ekfrm') {
							$('input[name=' + name + ']').val(v);
						}

						/* Begin: Legacy overrides */
						if (name.toLowerCase() == 'email' && $('input[name=sf_territory_manager_email]')) {
							$('input[name=sf_territory_manager_email]').val(v);
						}

						if (name.toLowerCase() == 'name') {
							if ($('input[name=sf_territory_manager]')) {
								$('input[name=sf_territory_manager]').val(v);
							}
							else {
								if (v.indexOf(' ') < 0) {
									$('input[name=sf_first_name]').val(v);
								}
								else {
									$('input[name=sf_first_name]').val(v.substring(0, v.indexOf(' ')));
									$('input[name=sf_last_name]').val(v.substring(v.indexOf(' ') + 1, v.length));
								}
							}
						}

						if (name.toLowerCase() == 'email') {
							$('input[name=sf_email]').val(v);
						}
						/* End: Legacy overrides */
					}
				}
			}
		}

		var init = function () {

			/* CAPTCHA Code Additions */
			$(function () {
				populateRepForm();

				// Creates an Ektron form submission with our own button
				$("#ctl00_ctl00_mainMasterContentPlaceHolder_mainMasterContentPlaceHolder_captchaSubmit").click(function () {
					var FormIsValid = EkFmValidate(document.getElementById('ctl00_ctl00_mainMasterContentPlaceHolder_mainMasterContentPlaceHolder_FormBlock1'));

					if (FormIsValid) {
						var fid = $('input[name="EktFormId"]').val();
						Ektron.FormBlock.setState("__ekFormState_ctl00_ctl00_mainMasterContentPlaceHolder_mainMasterContentPlaceHolder_FormBlock1", "in");
						Ektron.FormBlock.setState("__ekFormId_ctl00_ctl00_mainMasterContentPlaceHolder_mainMasterContentPlaceHolder_FormBlock1", fid);
						Ektron.FormBlock.setState("ApplicationAPI" + fid, "postback");

						return true;
					}
					else {
						alert("Sorry, the information you entered in the form is not complete. Please try again.");
						return false;
					}
				});
				// Catches the enter key on the CAPTCHA and submits the form
				$('#recaptcha_response_field').keyup(function (event) {
					if (event.keyCode == '13') { event.preventDefault(); $('#ctl00_ctl00_mainMasterContentPlaceHolder_mainMasterContentPlaceHolder_captchaSubmit').click(); }
				});

			});

		};



		// Make it stick!
		init();

	};
});

