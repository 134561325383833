/* global requirejs */

/* ================================
 * RequireJS Config and App Initialization
 * ================================ */

/* -- Config -- */
requirejs.config({
	baseUrl: '/scripts/',
	paths: {
		dropdownAutoSubmit: 'lib/jquery-plugins/jquery.dropdownautosubmit',
		dropdownRedirect: 'lib/jquery-plugins/jquery.dropdownredirect',
		equalHeight: 'lib/jquery-plugins/jquery.equalheight',
		fixedHeader: 'lib/jquery-plugins/jquery.fixedheader',
		formFieldExpander: 'lib/jquery-plugins/jquery.formfieldexpander',
		menuExpander: 'lib/jquery-plugins/jquery.menuExpander',
		offcanvasMenu: 'lib/jquery-plugins/jquery.offcanvasmenu',
		responsiveDom: 'lib/jquery-plugins/jquery.responsivedom',
		salesRepLocator: 'lib/jquery-plugins/jquery.salesreplocator',
		setupCaptcha: 'lib/jquery-plugins/jquery.captcha',
		jquery: '../bower_components/jquery/dist/jquery',
		core: '../bower_components/jquery-ui/ui/core',
		widget: '../bower_components/jquery-ui/ui/widget',
		'jquery-ui-tabs': '../bower_components/jquery-ui/ui/tabs',
		'jquery-ui-accordion': '../bower_components/jquery-ui/ui/accordion',
		almond: '../bower_components/almond/almond',
		angular: '../bower_components/angular/angular',
		'angular-route': '../bower_components/angular-route/angular-route',
		'google-code-prettify': '../bower_components/google-code-prettify/bin/prettify.min',
		'slick-carousel': '../bower_components/slick-carousel/slick/slick.min',
		'magnific-popup': '../bower_components/magnific-popup/dist/jquery.magnific-popup',
		matchMedia: '../bower_components/matchMedia.js/matchMedia',
		modernizr: '../bower_components/modernizr/modernizr'
	},
	packages: [
		{
			name: 'picturefill',
			main: 'dist/picturefill.js',
			location: '../bower_components/picturefill'
		}
	],
	urlArgs: 'bust=1430423877153'
});

/* -- Initialize App (app.js) -- */
requirejs(['app']);

define("main", function(){});

