/* jslint browser: true */
/* global define */

/** A jQuery plugin to create an off-canvas menu on small screens */
define('offcanvasMenu',['jquery'], function ($) {
	'use strict';

	$.fn.offcanvasMenu = function (options) {
		var settings = $.extend({
			maxWidth: 640,
			menuText: 'Menu',
			closeText: 'Close',
			triggerPlaceholder: null,
			defaultClassName: 'offcanvas-menu',
			wrapperClassName: 'offcanvas-wrapper',
			triggerClassName: 'offcanvas-trigger',
			closeClassName: 'offcanvas-close',
			visibleClassName: 'offcanvas-visible',
			onMenuImplemented: null
		}, options);
		var menu = this;
		var isActive = false;
		var iOs = /iPad|iPhone|iPod/.test(navigator.platform);
		var menuWrapper, menuCopy, menuTrigger;
		var bodyNode;

		var init = function() {
			// Wrap all content
			menuWrapper = $('<div class="' + settings.wrapperClassName + '"></div>');
			bodyNode = $('body:first');
			bodyNode.wrapInner(menuWrapper);

			// Implement or destroy menu based on viewport width
			$(window).on('resize.offcanvasMenu', function() {
				setTimeout(function() {
					var widthMatches = matchMedia('only screen and (max-width: ' + (settings.maxWidth - 1) + 'px)').matches;

					if (!isActive && widthMatches) {
						implement();
					} else if (isActive && !widthMatches) {
						destroy();
					}
				}, 0);
			}).trigger('resize.offcanvasMenu');

			wrapSitecoreRibbon();
		};

		var wrapSitecoreRibbon = function () {
			// Watch for Sitecore ribbon and re-wrap it when it appears.
			var observer = new MutationObserver(function (mutations) {
				mutations.forEach(function (mutation) {
					var newNode, menuWrapper;

					if (mutation.addedNodes.length > 0) {
						newNode = mutation.addedNodes[0];

						if (newNode.id === 'scCrossPiece') {
							menuWrapper = document.querySelector('.' + settings.wrapperClassName);
							menuWrapper.insertBefore(newNode, menuWrapper.firstChild);
						}
					}
				});
			});

			observer.observe(document.body, {
				childList: true
			});
		};

		var implement = function() {
			var closeButton;

			// Create copy of menu to the left
			menuCopy =  $('<div class="' + settings.defaultClassName + '"/>');
			menuCopy.append(menu.clone());

			// Add "close" button
			closeButton = $('<span class="' + settings.closeClassName + '">' + settings.closeText + '</span>');
			menuCopy.prepend(closeButton);

			// Append menu
			$('body').children().first().before(menuCopy);

			// Replace original menu with a trigger element
			menuTrigger = $('<span class="' + settings.triggerClassName + '">' + settings.menuText + '</span>');

			if (settings.triggerPlaceholder !== null) {
				$(settings.triggerPlaceholder).append(menuTrigger);
			} else {
				menu.before(menuTrigger);
			}

			menu.hide();

			// Add event listeners
			menuTrigger.on('click.offcanvasMenu', function() {
				if (bodyNode.hasClass(settings.visibleClassName)) {
					bodyNode.removeClass(settings.visibleClassName);

					return false;
				} else {
					scrollToTop();
					bodyNode.addClass(settings.visibleClassName);

					return false;
				}
			});

			$('.' + settings.wrapperClassName).on('click.offcanvasMenu', function() {
				bodyNode.removeClass(settings.visibleClassName);
			});

			closeButton.on('click.offcanvasMenu', function() {
				bodyNode.removeClass(settings.visibleClassName);
			});

			// Mark as "active"
			isActive = true;

			// Run onMenuImplemented callback
			if (typeof settings.onMenuImplemented === 'function') {
				settings.onMenuImplemented();
			}
		};

		var destroy = function() {
			// Destroy the copied menu
			menuCopy.remove();

			// Restore original menu
			menuTrigger.remove();
			menu.show();

			// Mark as not "active"
			bodyNode.removeClass(settings.visibleClassName);
			isActive = false;
		};

		var scrollToTop = function() {
			// Newer iOS devices scroll the menu down with the page. *Face palm*
			// So... we'll scroll back to the top when the menu opens.
			if (iOs) {
				window.scrollTo(0, 0);
			}
		};

		// Time to mobilize this menu!
		setTimeout(init, 0);

		// Always return the target object to allow chaining.
		return this;
	};
});

