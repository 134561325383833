/* jslint browser: true */
/* global define */

/** A jQuery plugin to implement functionality for Sales Rep Locator page. */
define('salesRepLocator',['jquery'], function ($) {
	'use strict';

	$.fn.salesRepLocator = function () {
		var $reps = this;

		var init = function () {
			if ($reps.length <= 1) {
				// Only implement if more than one sales rep
				return;
			}

			// Create <select>
			var $selectWrap = $('<p class="js-sales-rep-selector-wrapper"/>');
			var $select = $('<select class="js-sales-rep-selector"/>');

			$select.append('<option value="">-- Choose Your Location --</option>');

			// Add locations to <select>
			var locations = {};
			var keys = [];
			var prop, n;

			$reps.each(function () {
				var $rep = $(this);
				var $locationSpans = $rep.find('.sales-rep-location');

				$locationSpans.each(function () {
					var $span = $(this);
					var title = $span.data('locationTitle');
					var abbr = $span.text().trim();

					if (!locations[title] && abbr.length === 2) {
						locations[title] = abbr;
					}
				});
			});

			for (prop in locations) {
				if (locations.hasOwnProperty(prop)) {
					keys.push(prop);
				}
			}

			if (keys.length <= 1) {
				// No need to show selector for just one location
				return;
			}

			keys.sort();

			for (n = 0; n < keys.length; n++) {
				var key = keys[n];
				var $option = $('<option value="' + locations[key] + '">'
					+ key + '</option>');

				$select.append($option);
			}

			$selectWrap.append($select);
			$reps.first().before($selectWrap);

			// Add event listener
			$select.on('change.salesRepLocator', function() {
				var selectedOption = $(this).find('option:selected');
				
				showHideReps(selectedOption.val());
			});

			// Hide all reps initially
			showHideReps('');
		};

		/**
		 * Shows or hides sales rep info depending on their location.
		 * @param {string} locationAbbr The location abbreviation to show.
		 */
		var showHideReps = function (locationAbbr) {
			$reps.each(function () {
				var $rep = $(this);
				var $locationSpans = $rep.find('.sales-rep-location');
				var visible = false;

				if (locationAbbr != '') {
					$locationSpans.each(function () {
						var $span = $(this);

						if ($span.text().indexOf(locationAbbr) > -1) {
							visible = true;
						}
					});
				}

				if (visible) {
					$rep.show();
				} else {
					$rep.hide();
				}
			});
		};

		// Let's go!
		init();

		// Always return the target object to allow chaining.
		return this;
	};
});

