/* jslint browser: true */
/* global define */

/** A jQuery plugin to redirect the browser when an item is chosen in a <select> */
define('dropdownRedirect',['jquery'], function ($) {
	'use strict';

	$.fn.dropdownRedirect = function (options) {
		var $select = this;
		var settings = $.extend({
			submitButton: null
		}, options);
		
		var preVal = '';

		var init = function() {
			// Hide the submit button if provided
			if (settings.submitButton !== null) {
				$(settings.submitButton).hide();
			}

			// Add event listener
			$select
			.on('focus.dropdownRedirect', function() {
				preVal = $(this).val();

				if (settings.onFocus != null) {
					settings.onFocus();
				}
			}).on('change.dropdownRedirect', function() {
				var selectedOption = $(this).find('option:selected');
				var url = selectedOption.val();

				if (url.length > 0) {
					var shouldContinue = true;

					if (settings.onBeforeRedirect != null) {
						shouldContinue = settings.onBeforeRedirect();
						$(this).val(preVal);
					}

					if (shouldContinue) window.location.href = url;
				}
			});

			// Add class for styling
			$select.addClass('js-dropdown-redirect');
		};

		// Let's go!
		init();

		// Always return the target object to allow chaining.
		return this;
	};
});

