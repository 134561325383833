/* jslint browser: true */
/* global stLight */

/* ================================
 * Application Scripts
 * ================================ */
var breakpoints = {
	tiny: 480,
	small: 640,
	medium: 800,
	large: 960
};

require(['matchMedia', 'modernizr'], function() {
	'use strict';

	// We have to require matchMedia before picturefill, because
	// picturefill comes with its own version of matchMedia
	// that is incompatible with IE 8.
	require(['picturefill']);
});

require(['jquery'], function($) {
	'use strict';

	/**
	 * Entry point
	 */
	var init = function() {
		$(function() {
			moveMenus();
			setupHeaderTools();
			setupMenu();
			setSubmenuHeights();
			makeHeaderSticky();
			setupLightboxes();
			initEktronBubbleMenus();
			setupCaptcha();
			setupTabbedContent();
			addFormFeatures();
			addNagBannerCloseButton();
			redirectOnDropdownChange();
			addVideoJsSupport();
			addSalesRepLocator();
			initCarousels();

			// Show page
			setTimeout(function() {
				$('body').css('visibility', 'visible');
				openLightBox();

				// Carousels are buggy in the current Firefox but can be fixed by
				// resizing the window.
				setTimeout(function() {
					$(window).trigger('resize');
				}, 500);
			}, 0);
		});
	};

	/**
	 * Move the secondary menu and the "Careers" menu item based on the viewport width
	 */
	var moveMenus = function() {
		var $topMenuPlaceholder = $('<ul class="js-top-menu"/>');
		var $secondaryNav = $('.secondary-nav:first');
		var $userMenu = $('.user-nav:first');
		var $lastMenuItem = $('.site-nav:first .move-upper');
		var $tools = $('.site-header .tools');

		// Add the top menu place holder
		$tools.append($topMenuPlaceholder);

		// Move the secondary menu into the header
		if ($secondaryNav.length === 1) {
			require(['responsiveDom'], function() {
				// Create a placeholder after the header tools
				var $menuPlaceholder = $('<div class="js-secondary-nav"/>');

				$tools.after($menuPlaceholder);

				// Run responsiveDom plugin
				$secondaryNav.responsiveDom({
					appendTo: $menuPlaceholder,
					atWidth: breakpoints.medium
				});
			});
		}

		// Move the user menu into the header
		if ($userMenu.length === 1) {
			require(['responsiveDom'], function() {
				var $li = $('<li/>');

				$topMenuPlaceholder.append($li);

				$userMenu.responsiveDom({
					appendTo: $li,
					atWidth: breakpoints.medium
				});
			});
		}

		// Move the last menu item to the header
		if ($lastMenuItem.length > 0) {
			require(['responsiveDom'], function() {
				$lastMenuItem.responsiveDom({
					appendTo: $topMenuPlaceholder,
					usePrepend: true,
					atWidth: breakpoints.medium
				});
			});
		}
	};

	/**
	 * Add functionality to header tools
	 */
	var setupHeaderTools = function() {
		var $header = $('.site-header:first');
		var $langSelector = $('.site-header .lang-selection');
		var $searchBox = $('.site-header [type=search]');

		// Add collapse/expand feature to search and language selector
		if ($searchBox.length === 1 && $langSelector.length === 1) {
			require(['formFieldExpander', 'magnific-popup', 'matchMedia'], function() {
				if ($('.js-search-activator').length === 0) {
					var $searchActivator = $('<span class="js-search-activator">Search</span>');
					var $langActivator = $('<span class="js-lang-activator">Language</span>');
					var searchBoxHtml = $searchBox.parent().html();
					var langSelectorHtml = $langSelector.parent().html();
					var $searchForm = $searchBox.closest('form');
					var $langForm = $langSelector.closest('form');

					var searchFormSettings = {
						action: $searchForm.attr('action'),
						method: $searchForm.attr('method')
					};

					var langFormSettings = {
						action: $langForm.attr('action'),
						method: $langForm.attr('method')
					};

					$searchBox.before($searchActivator);
					$langSelector.before($langActivator);

					$searchBox.formFieldExpander({
						activator: $searchActivator,
						minWidth: breakpoints.small
					});

					$langSelector.formFieldExpander({
						activator: $langActivator,
						minWidth: breakpoints.small
					});

					// Use lightbox for small viewports
					$searchActivator.magnificPopup({
						focus: 'input[type=search]',
						alignTop: true,
						mainClass: 'mfp-move-from-top',
						removalDelay: 500,
						disableOn: function() {
							return !matchMedia('(min-width: ' + breakpoints.small + 'px)').matches;
						},
						items: [{
							src: '<div class="top-notification mfp-with-anim"><form action="' + searchFormSettings.action + '" method="' + searchFormSettings.method + '" class="header-form-field">' + searchBoxHtml + '</form></div>',
							type: 'inline'
						}]
					});

					$langActivator.magnificPopup({
						focus: 'select',
						alignTop: true,
						mainClass: 'mfp-move-from-top',
						removalDelay: 500,
						disableOn: function() {
							return !matchMedia('(min-width: ' + breakpoints.small + 'px)').matches;
						},
						items: [{
							src: '<div class="top-notification mfp-with-anim"><form action="' + langFormSettings.action + '" method="' + langFormSettings.method + '" class="header-form-field">' + langSelectorHtml + '</form></div>',
							type: 'inline'
						}]
					});
				}

				// Language selector will redirect to a URL on selection change
				if ($langSelector.length > 0) {
					require(['dropdownRedirect'], function() {
						$langSelector.dropdownRedirect({
							submitButton: $langSelector.next('button'),
							onBeforeRedirect: function() {
								// Check for config variable to show language navigation warning
								if (typeof zollConfig === 'object' && zollConfig.langNavigationWarning) {
									return confirm(zollConfig.langNavigationWarning); 
								}

								return true;
							}
						});
					});
				}
			});
		}

		// Add class to header for styling
		$header.addClass('js-enabled');
	};

	/**
	 * Create off-canvas menu and menu expand/collapse features
	 */
	var setupMenu = function() {
		var $mainNav = $('.site-nav');

		if ($mainNav.length !== 1) {
			$('body').addClass('js-no-menu');

			return;
		}

		require(['offcanvasMenu', 'menuExpander', 'matchMedia', 'equalHeight'], function() {
			var $customHeaderExists = !!($('.site-header-custom-content').length);

			// Add off-canvas menu
			if ($('.offcanvas-menu').length === 0) {
				var $triggerPlaceholder = $('<span class="js-offcanvas-trigger-placeholder"/>');
				var $headerTools = $('.site-header .tools');
				var $userNav = $('.user-nav');
				var $secondaryNav = $('.secondary-nav');

				if ($customHeaderExists) {
					$mainNav.before($triggerPlaceholder);
				} else {
					$headerTools.prepend($triggerPlaceholder);
				}

				$mainNav.offcanvasMenu({
					maxWidth: breakpoints.medium,
					triggerPlaceholder: $triggerPlaceholder,
					closeText: zollConfig.offcanvasCloseText || 'Close Menu',
					onMenuImplemented: function() {
						var $userNavCopy = ($userNav.length === 1) ? $userNav.clone() : null;
						var $secondaryNavCopy = $secondaryNav.clone();
						var $offcanvasMenu = $('.offcanvas-menu:first');

						// Copy user menu to end of offcanvas menu
						if ($userNavCopy !== null) {
							$offcanvasMenu.append($userNavCopy);
						}

						// Copy secondary menu to end of offcanvas menu
						$offcanvasMenu.append($secondaryNavCopy);

						// Add expansion feature
						$offcanvasMenu.find('.site-nav > ul').menuExpander();

						// Reset submenu heights
						$offcanvasMenu.find('.site-nav .submenu > li').css('min-height', '');
					}
				});
			}

			// Add top-level menu drop-down
			var $topLevelLinks = $mainNav.find('.top-level-item');
			var menuExpandedClass = 'js-menu-expander-expanded';

			var setMenuExpandedClass = function($menuItem, isExpanded) {
				$menuItem.siblings().andSelf().removeClass(menuExpandedClass);

				if (isExpanded) {
					$menuItem.addClass(menuExpandedClass);
				}
			};

			$topLevelLinks.on('click.zoll', function(e) {
				if (!$(e.target).parent().is('.top-level-item')) {
					return;
				}

				var $menuItem = $(this);
				var $link = $menuItem.find('> a');
				var linkUri = $link.attr('href');
				var clickCount = $menuItem.data('clickCount');

				// We need to track the number of click events due to how
				// touch devices fire mouse event handlers
				if (typeof clickCount === 'undefined') {
					$menuItem.data('clickCount', 1);
				} else {
					$menuItem.data('clickCount', ++clickCount);

					if (clickCount % 2 === 0) {
						setMenuExpandedClass($menuItem, false);
					} else {
						setMenuExpandedClass($menuItem, true);
					}
				}

				$menuItem.siblings().removeData('clickCount');

				// If top-level link doesn't go anywhere, don't fire it
				if ((linkUri === '/' || linkUri === '#') && matchMedia('(min-width: ' + breakpoints.medium + 'px)').matches) {
					return false;
				}
			}).on('mouseenter.zoll', function() {
				setMenuExpandedClass($(this), true);
			}).on('mouseleave.zoll', function() {
				setMenuExpandedClass($(this), false);
			});

			// Add quaternary-level menu expanders
			$mainNav.find('.tertiary-menu').menuExpander({
				cssClass: 'js-quaternary-expander',
				callback: function($trigger) {
					var itemsPerRow = (matchMedia('(min-width: ' + breakpoints.large + 'px)').matches) ? 5 : 4;
					var $menuItems = $trigger.closest('.submenu').find('> li');
					var options = {
						watchWinResize: false,
						mediaQuery: '(min-width: ' + breakpoints.medium + 'px)'
					};

					// Clear heights
					$menuItems.css('min-height', '');

					// Adjust menu heights
					if ($menuItems.length > itemsPerRow) {
						$menuItems.slice(0, itemsPerRow).equalHeight(options);
						$menuItems.slice(itemsPerRow + 1).equalHeight(options);
					} else {
						$menuItems.equalHeight(options);
					}
				}
			});
		});
	};

	/**
	 * Initialize and configures carousels/sliders
	 */
	var initCarousels = function() {
		var $carousels = $('.carousel .slides');
		var $thumbnails = $('.thumbnail-row ul');
		var carouselDefaultOpts = {
			accessibility: false,
			autoplay: true,
			autoplaySpeed: 5000,
			arrows: false,
			dots: true,
			mobileFirst: true,
			responsive: [{
				breakpoint: breakpoints.medium - 1,
				settings: {
					arrows: true,
					dots: false
				}
			}]
		};

		if ($carousels.length > 0) {
			require(['slick-carousel'], function() {
				$carousels.each(function() {
					var $carousel = $(this);
					var carouselOpts = carouselDefaultOpts;

					if ($carousel.hasClass('slides-show-dots')) {
						carouselOpts.responsive[0].settings.dots = true;
					}

					$carousel.slick(carouselOpts);
				});
			});
		}

		if ($thumbnails.length > 0) {
			require(['slick-carousel'], function() {
				$thumbnails.slick({
					accessibility: false,
					slidesToShow: 2,
					slidesToScroll: 2,
					mobileFirst: true,
					responsive: [{
						breakpoint: breakpoints.small - 1,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 3
						}
					}, {
						breakpoint: breakpoints.medium - 1,
						settings: {
							slidesToShow: 4,
							slidesToScroll: 4
						}
					}]
				});
			});
		}
	};

	/**
	 * Set submenu rows to the same item
	 */
	var setSubmenuHeights = function() {
		var $submenus = $('.site-nav .submenu');

		if ($submenus.length > 0) {
			require(['equalHeight', 'matchMedia'], function() {
				var debounceTimer = 0;
				var resizeSubmenus = function() {
					var itemsPerRow = (matchMedia('(min-width: ' + breakpoints.large + 'px)').matches) ? 5 : 4;
					var options = {
						watchWinResize: false,
						mediaQuery: '(min-width: ' + breakpoints.medium + 'px)'
					};

					$submenus.each(function() {
						var $menuItems = $(this).find('> li');

						// Clear heights
						$menuItems.css('min-height', '');

						// Call equalHeight plugin
						if ($('.site-nav-single-submenu').length === 0) {
							if ($menuItems.length > itemsPerRow) {
								$menuItems.slice(0, itemsPerRow).equalHeight(options);
								$menuItems.slice(itemsPerRow + 1).equalHeight(options);
							} else {
								$menuItems.equalHeight(options);
							}
						}
					});
				};

				$(window).on('resize.zoll', function() {
					clearTimeout(debounceTimer);
					debounceTimer = setTimeout(resizeSubmenus, 250);
				}).trigger('resize.zoll');
			});
		}
	};

	/**
	 * Make header/nav stick to the top while scrolling
	 */
	var makeHeaderSticky = function() {
		var $customHeaderExists = !!($('.site-header-custom-content').length);

		if ($customHeaderExists) {
			// Don't make custom header sticky
			return;
		}

		var $header = $('.site-header:first');

		if ($header.length === 1) {
			require(['fixedHeader'], function() {
				$header.parent().fixedHeader({
					maxWidth: breakpoints.medium
				});
			});
		}
	};

	/**
	 * Make links with a "lightbox-link" class open in a lightbox
	 */
	var setupLightboxes = function() {
		var lightboxLinks = $('.lightbox-link, .modal50perc');

		if (lightboxLinks.length > 0) {
			require(['magnific-popup'], function() {
				var $body = $('body');

				lightboxLinks.each(function() {
					var lightboxLink = $(this);
					var lightboxSrc = lightboxLink.attr('href');
					var lightboxTitle = lightboxLink.attr('title');
					var lightboxEventAction = lightboxLink.attr('eventaction') != null ? lightboxLink.attr('eventaction') : 'View Video';
					var lightboxEventType = lightboxLink.attr('eventtype');
					var lightboxType = lightboxSrc.indexOf('#') === 0 ? 'inline' : 'iframe';

					var lightboxOptions = {
						src: lightboxSrc,
						type: lightboxType,
						mainClass: 'mfp-zoom-in',
						removalDelay: 500,
						callbacks: {
							beforeOpen: function() {
								$body.addClass('js-mfp-open');

								if (lightboxEventType == 'VideoEvent'
									&& typeof ga === 'function') {
									ga('send', 'event', 'Video View', lightboxEventAction, (lightboxTitle != null && lightboxTitle != '') ? lightboxTitle : lightboxSrc);
								}
							},
							beforeClose: function() {
								setTimeout(function() {
									$body.removeClass('js-mfp-open');
								}, 500);
							}
						}
					};

					if (lightboxType == 'iframe') {
						lightboxOptions.iframe = {
						 markup: '<div class="mfp-iframe-scaler">'+
				            '<div class="mfp-close"></div>'+
				            '<div class="mfp-iframe-wrapper">' +
				            '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
				            '</div>' +
				          '</div>'
				      	};
					}

					lightboxLink.magnificPopup(lightboxOptions);
				});
			});
		}
	};

	/**
	 * Opens all lightboxes that have a "lightbox-open" class
	 */
	var openLightBox = function() {
		require(['magnific-popup'], function() {
			$('.lightbox-open').each(function() {
				$.magnificPopup.open({
					items: {
						src: $(this)
					},
					type: 'inline'
				});
			});
		});
	};

	/**
	 * Sets up admin menu
	 */
	var initEktronBubbleMenus = function() {
		$('.ekt-page-edit-menu').hover(
			function() {
				$(this).children('ul').fadeIn(100);
			},
			function() {
				$(this).children('ul').fadeOut(500);
			}
		);
	};

	/**
	 * Sets up captcha
	 */
	var setupCaptcha = function() {
		require(['setupCaptcha'], function() {
			$.setupCaptcha();
		});
	};

	/**
	 * Implement jQuery UI tabs/accordions
	 */
	var setupTabbedContent = function() {
		require(['jquery-ui-accordion', 'jquery-ui-tabs', 'matchMedia'], function() {
			var $tabUis = $('.tabs-ui');

			var implementUi = function() {
				var isLargeViewport = matchMedia('(min-width: ' + breakpoints.small + 'px)').matches;

				$tabUis.each(function() {
					var $tabs = $(this);
					var $tabContent = $tabs.find('.tab-content');

					if (isLargeViewport) {
						// Use tabs for large viewports
						if ($tabContent.hasClass('ui-accordion')) {
							$tabContent.accordion('destroy');
						}

						if (!$tabs.hasClass('ui-tabs')) {
							$tabs.tabs();
						}
					} else {
						// Use accordions for small viewports
						if ($tabs.hasClass('ui-tabs')) {
							$tabs.tabs('destroy');
						}

						if (!$tabContent.hasClass('ui-accordion')) {
							$tabContent.accordion({
								header: 'h1',
								collapsible: true,
								active: false,
								heightStyle: 'content'
							});
						}
					};
				});
			};

			$(window).on('resize.zoll', debounce(implementUi, 250));

			implementUi();
		});
	};

	/**
	 * Make improvements to forms.
	 */
	var addFormFeatures = function () {
		var $autoDropdowns = $('.autosubmit');

		require(['dropdownAutoSubmit'], function () {
			$autoDropdowns.autosubmit({
				ignore: [''],
				hide: '.autosubmit-hide'
			});
		});
	};

	/**
	 * Allows nag banner to be hidden when close button is used. Also sets
	 * a cookie to prevent banner from appearing again.
	 */
	var addNagBannerCloseButton = function () {
		var $closeButton = $('#close-nag-banner');

		$closeButton.click(function (e) {
			var $nagBanner = $('.nag-banner');
			var cookieDate = new Date;

			e.preventDefault();

			// Set cookie
			cookieDate.setFullYear(cookieDate.getFullYear() + 1);
			document.cookie = 'hideCookieNotice=true; expires=' + cookieDate.toGMTString() + '; path=/';

			// Hide banner
			$nagBanner.slideUp(500);
		});
	};

	/**
	 * Redirects the browser to the selected value of a <select> element when it changes.
	 */
	var redirectOnDropdownChange = function () {
		var $stateDropdown = $('#state-leg-selector');

		$stateDropdown.on('change.zoll', function () {
			var selectedValue = $(this).val();

			if (selectedValue) {
				window.location = selectedValue;
			}
		});
	};

	/**
	 * Adds video.js scripts if page has any <video> elements.
	 */
	var addVideoJsSupport = function () {
		var videoNodes = document.getElementsByTagName('video');
		var head, videoJsLink, videoJsScript;

		if (videoNodes) {
			window.HELP_IMPROVE_VIDEOJS = false;

			// Create video.js style sheet
			videoJsLink = document.createElement('link');

			videoJsLink.rel = 'stylesheet';
			videoJsLink.href = '//vjs.zencdn.net/5.4.6/video-js.min.css';

			// Create video.js script
			videoJsScript = document.createElement('script');
			videoJsScript.src = '//vjs.zencdn.net/5.4.6/video.min.js';

			// Add style sheet and script to head
			head = document.head;

			head.appendChild(videoJsLink);
			head.appendChild(videoJsScript);
		}
	};

	/**
	 * If the page contains sales rep details, a UI will be created for them.
	 */
	var addSalesRepLocator = function () {
		var $salesReps = $('.sales-rep');

		if ($salesReps.length > 0) {
			require(['salesRepLocator'], function () {
				$salesReps.salesRepLocator();
			});
		}
	};

	/**
	* Returns a function that cannot be called in succession unless a specified
	* amount of time has passed
	* @param func - The function to debounce
	* @param wait - The wait time (ms) before running the function again
	* @returns The debounced function
	*/
	var debounce = function(func, wait) {
		var timeout;

		return function() {
			clearTimeout(timeout);

			timeout = setTimeout(function() {
				func();
			}, wait);
		};
	};

	/* -- Allow XPL to re-run scripts when components load -- */
	$(window).on('xplComponentLoad xplRouteChange', function() {
		setTimeout(init, 0);
	});

	/* -- Let's go! -- */
	setTimeout(function() {
		init();

		// Use Selectivizr and RespondJS for IE 6-8
		if ($('.lt-ie9').length > 0) {
			var isLocalhost = window.location.href.indexOf('localhost') > -1;
			var selectivizrUri = '/scripts/vendor/selectivizr.js';

			if (!isLocalhost) {
				selectivizrUri = '/scripts/vendor/selectivizr.js';
			}

			$.getScript(selectivizrUri, function() {
				$.getScript('//cdnjs.cloudflare.com/ajax/libs/respond.js/1.4.2/respond.js');
			});
		}
	}, 0);
});

define("app", function(){});

