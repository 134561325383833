/* jslint browser: true */
/* global define */

/**
 * The parent form of the provided element(s) will be submitted when its/their value changes.
 * @param options.ignore  - An ignore option is available (array) to prevent the form from
 *                          submitting when certain values are chosen. By default, options with
 *                          an empty string value are ignored.
 * @param options.trigger - If trigger is omitted, the parent form will be submitted.
 *                          Otherwise, a click event will be triggered on the provided jQuery
 *                          object.
 * @param options.hide    - A jQuery selector for any elements that should be hidden (e.g., a
 *                          submit button that is no longer needed.)
*/
define('dropdownAutoSubmit',['jquery'], function ($) {
	'use strict';

	$.fn.autosubmit = function (options) {
		var settings = $.extend({
			'ignore': ['']
		}, options);

		// Hide elements.
		if (settings.hide) {
			$(settings.hide).hide();
		}

		// Submit form on "change" event.
		this.change(function () {
			var $this = $(this);

			if ($.inArray($this.val(), settings.ignore) === -1) {
				if (typeof settings.trigger !== 'undefined') {
					settings.trigger.click();
				} else {
					$this.closest('form').submit();
				}
			}
		})

		return this;
	}
});

